import Chart from "chart.js/auto";
import get_alpha_colors from '/src/js/vars.js';

function get_chart_option() {
    return {
        responsive: true,
        type: 'doughnut',
        data: {
            labels: window.weeds_name,
            devicePixelRatio: 4,
            datasets: [
                {
                    data: window.weeds_count,
                    borderColor: colors,
                    backgroundColor: get_alpha_colors(0.7),
                }
            ]
        },
        options: {
            plugins: {
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false
                }
            },
            tooltips: {
                enabled: false
            }
        }
    };
}

export default function create_chart() {
    var myDoughnutChart = new Chart("chart", get_chart_option());
    document.getElementById("cible").style.display = "block";
}
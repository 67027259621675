window.colors = ['#47a857', '#d8df50', '#44934d', '#59b37a', '#51a49e'];

window.weeds = []
window.weeds_count = []

export default function get_alpha_colors(alpha = 0.5) {
    var alpha_color = [];
    for (var color of colors) {
        alpha_color.push(color + Math.floor(alpha * 255).toString(16));
    }
    return alpha_color;
}

import Base from 'mapbox-gl-controls/src/Base/Base';
import Button from 'mapbox-gl-controls/src/Button/Button';

const svg_weed = `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="16.000000pt" height="16.000000pt" viewBox="0 0 16.000000 16.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 132 c0 -25 20 -48 48 -55 19 -5 22 -1 22 28 0 34 -13 45 -51 45
-12 0 -19 -7 -19 -18z m58 -15 c4 -22 -27 -35 -39 -16 -5 8 -9 20 -9 27 0 20
44 10 48 -11z"/>
<path d="M108 129 c-10 -5 -18 -21 -18 -34 0 -13 -4 -27 -10 -30 -5 -3 -10
-18 -9 -33 1 -26 1 -26 10 6 5 17 13 32 17 32 4 0 13 10 21 23 13 20 13 21 -2
8 -15 -11 -17 -11 -17 2 0 14 20 27 41 27 13 0 11 -34 -3 -50 -11 -12 -10 -12
5 -1 9 7 17 24 17 37 0 26 -21 31 -52 13z"/>
</g>
</svg>
`;


function getWeed() {
    return (new DOMParser().parseFromString(svg_weed, 'image/svg+xml')).firstChild as SVGElement;
}


export default class ViewControl extends Base {
    extractToogle: Button

    constructor() {
        super();
        this.extractToogle = new Button();
        this.extractToogle.addClassName('selected_button');
    }

    insert() {
        this.addClassName('mapbox-extration-layer');
        this.extractToogle.setIcon(getWeed());
        this.extractToogle.onClick(() => {
            const visibility = this.map.getLayoutProperty(
                "pointsLayer",
                'visibility'
            );
            if (visibility === 'visible' || visibility == undefined) {
                this.map.setLayoutProperty("pointsLayer", 'visibility', 'none');
                this.extractToogle.removeClassName('selected_button');
            } else {
                this.extractToogle.addClassName('selected_button');
                this.map.setLayoutProperty(
                    "pointsLayer",
                    'visibility',
                    'visible'
                );
            }
        });
        this.addButton(this.extractToogle);
    }

    onAddControl() {
        this.insert();
    }
}

import mapboxgl from 'mapbox-gl';
import { RulerControl, StylesControl, ZoomControl } from 'mapbox-gl-controls';
import ViewControl from '/src/ts/ViewControl'

var lat_center = 48.85304;
var long_center = 2.3499075;
var colors_match = [];
var zoom = 0.5;
var map;

function add_layers(coords_field, coords_path, coords_path_plant) {
    //Field zone
    map.addSource('field', {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    coords_field
                ]
            }
        }
    });
    map.addLayer({
        'id': 'fieldLayer',
        'type': 'fill',
        'source': 'field',
        'layout': {},
        'paint': {
            'fill-color': '#0620FB',
            'fill-opacity': 0.4
        }
    });

    //Field line
    map.addSource('field_corner', {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': coords_field
            }
        }
    });
    map.addLayer({
        'id': 'field_cornerLayer',
        'type': 'line',
        'source': 'field_corner',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': '#0620FB',
            'line-width': 4
        }
    });
    //Path line robot
    map.addSource('pathRobot', {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': coords_path
            }
        }
    });
    map.addLayer({
        'id': 'pathRobotLayer',
        'type': 'line',
        'source': 'pathRobot',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': 'red',
            'line-width': 2
        }
    });
    //Path point robot
    map.addSource('points', {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': coords_path_plant
        }
    });
    map.addLayer({
        'id': 'pointsLayer',
        'type': 'circle',
        'source': 'points',
        'paint': {
            'circle-radius': 4,
            'circle-color': colors_match
        }
    });
}

export default function create_map(res_api, coords_field, coords_path, coords_path_plant) {

    mapboxgl.accessToken = 'pk.eyJ1IjoidmluY2VudGxiIiwiYSI6ImNrY2F2YTA5NjF5c3kzMG8wbG5zbjk5cjcifQ.p9V3BtVZngNW1L8MRoALaw';
    map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [long_center, lat_center],
        zoom: zoom,
        preserveDrawingBuffer: true
    });

    map.addControl(new RulerControl({
        units: 'meters',
        labelFormat: n => `${n.toFixed(2)} m`,
    }), 'top-right');

    map.addControl(new ZoomControl(), 'top-right');

    map.addControl(new StylesControl({
        styles: [
            {
                label: "Road",
                styleName: 'Mapbox Streets',
                styleUrl: 'mapbox://styles/mapbox/streets-v12'
            }, {
                label: 'Satellite',
                styleName: 'Satellite',
                styleUrl: 'mapbox://styles/mapbox/satellite-streets-v12',
            },
        ],
        onChange: (style) => {
            if (style.label == "Road") {
                document.getElementById("sattelite_button").setAttribute("class", "");
                document.getElementById("road_button").setAttribute("class", "selected_button");
            } else {
                document.getElementById("sattelite_button").setAttribute("class", "selected_button");
                document.getElementById("road_button").setAttribute("class", "");
            }
            setTimeout(() => {
                map.style.stylesheet.layers.forEach(function (layer) {
                    if (layer.type === 'symbol') {
                        map.removeLayer(layer.id);
                    }
                });
                add_layers(coords_field, coords_path, coords_path_plant);
            }, 100);
        }
    }), 'top-left');

    map.addControl(new ViewControl(), 'top-left');

    var buttons = document.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
        let button = buttons[i];
        if (button.textContent == "Road") {
            button.innerHTML = '<i class="fa fa-road fa-lg"></i>';
            button.setAttribute("id", "road_button");
        }
        else if (button.textContent == "Satellite") {
            button.innerHTML = '<i style="padding-bottom: 5.6px;" class="fa fa-globe fa-lg"></i>';
            button.setAttribute("id", "sattelite_button");
            button.setAttribute("class", "selected_button");
        }
    }

    map.on('load', function () {

        map.style.stylesheet.layers.forEach(function (layer) {
            if (layer.type === 'symbol') {
                map.removeLayer(layer.id);
            }
        });

        zoom = 17;

        if (res_api != null) {

            colors_match = [
                'match',
                ['get', 'Type'],
                'Plantain_great',
                colors[0],
                'Dandellion',
                colors[1],
                'Plantain_narrowleaf',
                colors[2],
                'Daisy',
                colors[3],
                'Porcelle',
                colors[4],
                colors[4]
            ]

            var lat = 0;
            var long = 0;
            for (let i = 0; i < coords_field.length; i++) {
                long += coords_field[i][0];
                lat += coords_field[i][1];
            }

            lat_center = lat / coords_field.length;
            long_center = long / coords_field.length;

            add_layers(coords_field, coords_path, coords_path_plant);
        }

        map.flyTo({
            center: [long_center, lat_center],
            zoom: zoom,
            duration: 5000,
            essential: true // This animation is considered essential with
        });

    });

}
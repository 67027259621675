import $ from "jquery";
import moment from "moment"
import create_map from '/src/js/map.js';
import create_chart from '/src/js/graph.js'

function sort_object(obj) {
    var items = Object.keys(obj).map(function (key) {
        return [key, obj[key]];
    });
    items.sort(function (first, second) {
        return second[1] - first[1];
    });
    var sorted_obj = {}
    $.each(items, function (k, v) {
        sorted_obj[v[0]] = v[1];
    })
    return sorted_obj;
}

// Use to convert from lat long dist to meters
function measure(lat1, lon1, lat2, lon2) {  // generally used geo measurement function
    var R = 6378.137; // Radius of earth in KM
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000; // meters
}

const icon_print = document.querySelector("#print");
icon_print.addEventListener("click", () => {
    print();
});

function put_field_name(res_api) {
    var name = decodeURIComponent(res_api.field.label);
    document.getElementById("modify_zone").textContent = name;
}

function put_date_and_time_duration(res_api) {
    var date_start = new Date(res_api.session.start_time);
    var date_end = new Date(res_api.session.end_time);
    var duration = date_end - date_start;
    document.getElementById("modify_date").textContent = moment(date_start).format('l');
    document.getElementById("modify_start").textContent = moment(date_start).format('LT');
    document.getElementById("modify_end").textContent = moment(date_end).format('LT');

    var h, m, s
    h = Math.floor(duration / 1000 / 60 / 60);
    m = Math.floor((duration / 1000 / 60 / 60 - h) * 60);
    s = Math.floor(((duration / 1000 / 60 / 60 - h) * 60 - m) * 60);
    var m_s = (m < 10) ? '0' + m.toString() : m.toString();
    var s_s = (s < 10) ? '0' + s.toString() : s.toString();
    document.getElementById("modify_duration").textContent = h + ":" + m_s + ":" + s_s;

    //document.title = document.title += " of " + moment(date_start).format('ll') + " at " + moment(date_start).format('LT');
    document.title = "Violette_report_" + (date_start.getMonth() + 1) + date_start.getDate() + date_start.getFullYear() + "_" + date_start.getHours() + date_start.getMinutes();
}

function calculate_total_area(res_api) {
    var area = 1;
    for (var i = 0; i < 2; i++) {
        area *= measure(
            res_api.fields_corners[i]["latitude"],
            res_api.fields_corners[i]["longitude"],
            res_api.fields_corners[i + 1]["latitude"],
            res_api.fields_corners[i + 1]["longitude"]
        )
    }
    document.getElementById("modify_total_area").innerText = Math.round(area);
}

function calculate_robot_path_length(res_api) {
    window.path_length = 0;
    for (var i = 0; i < (res_api.points_of_paths.length - 1); i++) {
        path_length += measure(
            res_api.points_of_paths[i]["latitude"],
            res_api.points_of_paths[i]["longitude"],
            res_api.points_of_paths[i + 1]["latitude"],
            res_api.points_of_paths[i + 1]["longitude"]
        )
    }
}

function calculate_covered_area() {
    document.getElementById("modify_covered_area").innerText = Math.round(window.path_length * document.getElementById("robot_width").value);
}

function load_session_data(onLoadCallback) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('session_id')) {
        const session_id = urlParams.get('session_id');
        const url = window.location.origin + '/api/v1/data_gathering/get_report_data';

        $.ajax({
            method: "GET",
            url: url,
            data: {
                session_id: session_id,
            },
            headers: {
                accept: "application/json;"
            },
            dataType: "json",
            success: function (data) {
                put_field_name(data);
                put_date_and_time_duration(data);
                onLoadCallback(data);
            },
            error: function (error) {
                console.log(error);
            },
        });

    } else {
        onLoadCallback(null);
    }
}

window.onload = () => {
    var all_circle = document.querySelectorAll("circle");
    for (var num of Array(all_circle.length - 1).keys()) {
        all_circle[num].setAttribute("fill", colors.at(num));
    }
    load_session_data((res_api) => {

        var coords_field = [];
        var coords_path = [];
        var coords_path_plant = [];

        for (var coords of res_api.fields_corners) {
            coords_field.push([coords.longitude, coords.latitude]);
        }
        coords_field.push([res_api.fields_corners[0].longitude, res_api.fields_corners[0].latitude]);

        for (var coords of res_api.points_of_paths) {
            coords_path.push([coords.longitude, coords.latitude]);
        }

        var weed_names_count = {};

        for (var extraction of res_api.extracted_weed_with_GPS_point_with_weed_type) {
            if (extraction.label in weed_names_count) {
                weed_names_count[extraction.label] += 1;
            } else {
                weed_names_count[extraction.label] = 1;
            }
            coords_path_plant.push(
                {
                    'type': 'Feature',
                    "properties": {
                        "Type": extraction.label
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [extraction.longitude, extraction.latitude]
                    }
                },
            )
        }

        var sorted_weed_names_count = sort_object(weed_names_count);

        window.weeds_name = Object.keys(sorted_weed_names_count);
        window.weeds_count = Object.values(sorted_weed_names_count);

        for (var i = 0; i < weeds_name.length; i++) {
            document.getElementById("modify_weed_name_" + (i + 1)).innerText = weeds_name[i];
            document.getElementById("modify_weed_count_" + (i + 1)).innerText = weeds_count[i];
        }

        for (var i = weeds_name.length; i < 5; i++) {
            document.getElementById("modify_weed_name_" + (i + 1)).parentNode.style = "display:none";
        }

        document.getElementById("modify_total").innerText = weeds_count.reduce((partialSum, a) => partialSum + a, 0);

        calculate_total_area(res_api);
        calculate_robot_path_length(res_api);
        calculate_covered_area();
        document.getElementById("robot_width").addEventListener("change", calculate_covered_area);

        create_map(res_api, coords_field, coords_path, coords_path_plant);
        create_chart();
    });
}